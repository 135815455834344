;(function($){



    function Calendar() {



        var _ = this;



        $.get($("#app_url").val() + "/api/" + "agenda", function(data)

        {



             _.thisMonth = moment().format('YYYY-MM');

            // Events to load into calendar

            var events = [];



            $(data).each(function(index, value) {

                var arr_data_start = data[index].datestartevent;

                var arr_data_end = data[index].dateendevent;

                

                var locations = {

                    endDate: arr_data_end,

                    startDate :arr_data_start,

                    title :data[index].title,

                };

                 

                events.push(locations);


            });

            console.log(events);


            //  _.eventArray = [

            //     {

            //         title: 'Multi-Day Event',

            //         endDate: '2017-07-06',

            //         startDate: '2017-07-01'

            //     }, {

            //         endDate: '2017-08-26',

            //         startDate:'2017-08-20',

            //         title: 'Another Multi-Day Event'

            //     }

            // ];



            $('.caleandar').clndr({

                events: events,

                clickEvents: {

                    click: function (target) {

                        $(".legend").css({

                            display: 'block'

                        });

                        console.log(target);

                        var html = "";

                        target.events.forEach(function(item){
                            
                            var start = item.startDate.split("-");
                            var end = item.endDate.split("-");
                            var title = item.title != " " ? item.title : " ";

                            html += "<div style='display: block'>"+start[2]+"-"+end[2]+"<span class='cld-title'>"+title+"</span></div>";
                        }) 
  
                        $(".legend").html(html);

                    },

                },

                multiDayEvents: {

                    endDate: 'endDate',

                    startDate: 'startDate'

                },

            });

        });

    }



   



   	new Calendar();



}(jQuery));