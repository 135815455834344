;(function($){

    function Contact() {
   	 	var _ = this;

        $.validator.addMethod("valueNotEquals", function(value, element, arg){
            return arg != value;
        }, "Informe sua ocupação");

        $.validator.addMethod("valueNotEqualstwo", function(value, element, arg){
            return arg != value;
        }, "Informe sua instituição");

        $("#form-voluntions").validate({
            rules: {
                name: { required: true },
                email: { required: true, email: true },
                phone: { required: true },
                course: { required: true },
                functions : { valueNotEquals: "default" },
                institutions : { valueNotEqualstwo: "default" }, 
                checkbox: { required: true },
            },
            messages: {
                name: { required: "Informe seu nome" },
                email: { required: 'Informe o seu email', email: 'Ops, informe um email válido' },
                phone: { required: "Informe o nº do seu telefone" },
                course: { required: "Insira um curso" },
                functions: { required: "Informe sua ocupação" },
                institutions: { required: "Informe sua instituição" },
                checkbox: { required: "Aceite os termos de uso" },

            },

            submitHandler: function( form ) {
                
                $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: $("#app_url").val() + "/api/form-voluntions",
                    data: $( form ).serialize(),
                    success: function(result)
                    {

                       swal("Contato enviado com sucesso!", "Em breve nosso atendimento retornará o seu contato.", "success")
                       form.reset();
                       grecaptcha.reset();
                        
                    },
                    error: function(response) {
                        swal("Atenção!", "por favor marque o campo captcha!", "error")
                    }
                });
            }
        });
    }

   	new Contact();

}(jQuery));
 
 
 
 
