;(function($){

    function Slides() {

   	 	var _ =  this;

   	 	_.contentSlider();
   	 	_.projectsSlider();
    }

    Slides.prototype.contentSlider =  function() {

       var _ = this;

       _.elementPresentationSlider = $('.content-slider');

      

       _.elementPresentationSlider.slick({
	        fade: true,
	        arrows: false,
	        dots: false,
	        autoplay: true,
	        autoplaySpeed: 4000,
	        pauseOnFocus:false,
          	responsive: [
            {
              breakpoint: 990,
                settings: {
                  arrows:false,
                }
            },
            {
              breakpoint: 480,
                settings: {
                  arrows:false,
                }
            }
          ]
  	   });
    };

    Slides.prototype.projectsSlider =  function() {

       var _ = this;

       _.elementPresentationSlider = $('.slide-projects');

      

       _.elementPresentationSlider.slick({
	        arrows: false,
	        dots: true,
	        slidesToShow: 4,
	        customPaging : function(slider, i) {
		        var thumb = $(slider.$slides[i]).data();
		        return '<a><div class="dots"><div class="bar"></div></div></a>';
		    },
          	responsive: [
            {
              breakpoint: 990,
                settings: {
                  slidesToShow: 2,
                }
            },
            {
              breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                }
            }
          ]
  	   });
    };
    
   	new Slides();

}(jQuery));
 
 
 
 
